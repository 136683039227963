import { React, useState, useEffect } from "react";
import axios from "axios";
import ScanIdScreen from "./Components/ScanIdScreen";
import InvalidId from "./Components/InvalidId";
import ErrorScreen from "./Components/ErrorScreen";
import FourErrorScreen from "./Components/FourErrorScreen";
import FiveErrorScreen from "./Components/FiveErrorScreen";
import CardScanned from "./Components/CardScanned";
import FinishPage from "./Components/FinishPage";
import Sfx from "./Sounds/Sfx.mp3";
import FinishBell from "./Sounds/FinishBell.mp3";
import Error from "./Sounds/error.mp3";
import useSound from "use-sound";
import AuthErrorScreen from "./Components/AuthErrorScreen";

function DoorEntry() {
  const [idData, setIdData] = useState("");
  const [activePage, setActivePage] = useState("IdPage");
  const [errorState, setErrorState] = useState("");

  const token = localStorage.getItem("token");

  const [play] = useSound(Sfx);
  const [playFinish] = useSound(FinishBell);
  const [playError] = useSound(Error);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  useEffect(() => {
    if (activePage === "Finish") {
      playFinish();
      finish();
    }
    if (activePage === "CardScanned") {
      handleSubmit();
    }
    if (activePage === "IdPage") {
      setIdData("");
      setErrorState("");
    }
  }, [activePage]);

  const activateIdPage = () => {
    play();
    setActivePage("IdPage");
  };

  const idFunction = (scanData) => {
    let isnum = /^\d+$/.test(scanData);
    if (isnum && scanData.length === 12) {
      setIdData(scanData);
      setActivePage("CardScanned");
    } else {
      setActivePage("InvalidScan");
      playError();
    }
  };

  const backHome = () => {
    play();
    setActivePage("IdPage");
  };

  const finish = () => {
    setTimeout(() => {
      setActivePage("IdPage");
    }, 1200);
  };

  const handleSubmit = async () => {
    const loginFormData = {
      cardUserId: idData,
    };

    try {
      const response = await axios({
        method: "post",
        url: "https://api.venuenow.tech/door-device/user-checkin",
        data: loginFormData,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      });
      let statusResult = response.status.toString();
      const startsWithTwo = statusResult.startsWith("2");
      if (startsWithTwo) {
        if (response.data.accessGranted === false) {
          setActivePage("ErrorScreen");
          playError();
        } else if (response.data.accessGranted === true) {
          setActivePage("Finish");
        }
      }
    } catch (error) {
      let errorStatusResult = error.response.status.toString();
      const startsWithFour = errorStatusResult.startsWith("4");
      const startsWithFive = errorStatusResult.startsWith("5");

      if (startsWithFour) {
        if (errorStatusResult === "401") {
          playError();
          setActivePage("AuthErrorScreen");
          console.log("Error is Authentication Related");
        } else {
          playError();
          setActivePage("FourErrorScreen");
        }
      } else if (startsWithFive) {
        playError();
        setActivePage("FiveErrorScreen");
      }
    }
  };

  return (
    <form className="body">
      {activePage === "IdPage" && <ScanIdScreen setIdData={idFunction} />}

      {activePage === "CardScanned" && <CardScanned />}

      {activePage === "InvalidScan" && (
        <InvalidId backToScan={activateIdPage} />
      )}
      {activePage === "ErrorScreen" && (
        <ErrorScreen backHome={backHome} errorState={errorState} />
      )}
      {activePage === "FourErrorScreen" && (
        <FourErrorScreen backHome={backHome} />
      )}
      {activePage === "AuthErrorScreen" && <AuthErrorScreen />}
      {activePage === "FiveErrorScreen" && (
        <FiveErrorScreen backHome={backHome} />
      )}

      {activePage === "Finish" && <FinishPage />}
    </form>
  );
}

export default DoorEntry;
