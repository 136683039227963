import { useState, useEffect, React } from "react";
import "./ScanIdScreen.scss";
import Scan from "../Images/scan.gif";
import NoviraLogo from "../Images/NoviraLogo.webp";
import HomeVideo from "../Images/Novira-Eco-Bin_door-entry.mp4";

function ScanIdScreen({ setIdData }) {
  const [showScanPackage, setShowScanPackage] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowScanPackage((prev) => !prev);
    }, 26000);

    return () => clearInterval(interval);
  }, []);

  const onBlur = (e) => {
    window.setTimeout(() => e.target.focus(), 0);
  };

  const videoScreen = () => {
    return (
      <div className="home__image">
        <video className="home__image__video" autoPlay loop muted>
          <source src={HomeVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  };

  return (
    <div className="idScan__base">
      <div className="idScan__title">
        <h1 className="idScan__title__text">
          Swipe your ID card over the scanner below
        </h1>
        <input
          className="idScan__image__input"
          autoFocus
          style={{ border: "none" }}
          type="text"
          onBlur={onBlur}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setIdData(e.target.value);
            }
          }}
        />
      </div>

      {showScanPackage ? (
        <>
          <div className="idScan__image">
            <img
              className="idScan__image__logo"
              src={Scan}
              alt="Scan Animation"
            />
          </div>
          <div className="idScan__footer">
            <img className="idScan__footer__logo" src={NoviraLogo} alt="Logo" />
          </div>
        </>
      ) : (
        videoScreen()
      )}
    </div>
  );
}

export default ScanIdScreen;
